const dashboard = 'dashboard';
const upload = 'upload';
const organization = 'organization';
const fileUpload = 'rule_file_upload';
const userEvalPermissionDetails = 'user_eval_permission_details';
const frequency = 'frequency';
const workflow = 'workflow';
const program = 'program_configuration';
const heirarchy = 'iprogram-hierarchy-master';
const updateUserLanguage = 'update_user_language';
const getUnifiedSourceColumnList = 'get_unified_source_column_list';
const roles = 'roles';
const qualifier = 'payment-party-configuration';
const programConfigDetails = 'program_config_details';
const rulesPlan = 'rule_plan';
const ruleGroup = 'rule_group';
const rules = 'rules';
const dataSourceColumnList = 'data_source_column_list';
const getOutputVariableList = 'get_output_variable_list';
const deleteRulesAfterUniChange = 'delete_rules_of_rule_plan_id';
const workflowapproverDetails = 'get_workflow_approver_details';
const userRoles = 'get_user_roles_program_details';
const getReportingPayoutList = 'get_reporting_payout_variable_details';
const updateUserRoleProgramDetails = 'update_user_role_program_details';
const incentiveProgram = 'incentive_program';

const apiEndpoint = {
  dashboard,
  upload,
  organization,
  fileUpload,
  userEvalPermissionDetails,
  frequency,
  workflow,
  program,
  heirarchy,
  updateUserLanguage,
  getUnifiedSourceColumnList,
  roles,
  qualifier,
  programConfigDetails,
  rulesPlan,
  ruleGroup,
  rules,
  dataSourceColumnList,
  getOutputVariableList,
  deleteRulesAfterUniChange,
  workflowapproverDetails,
  userRoles,
  getReportingPayoutList,
  updateUserRoleProgramDetails,
  incentiveProgram,
};

export default apiEndpoint;
