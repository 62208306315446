import {
    Utils, Config, JsonGroup, Field, JsonLogicTree,
    JsonTree,
    JsonLogicResult
} from '@react-awesome-query-builder/core';

interface CaseItem {
    whenSql?: string;
    thenSql?: string;
    whenJsonLogic?: JsonLogicResult;
    thenJsonLogic?: JsonLogicResult;
    when: JsonGroup;
    thenQuery?: JsonGroup;
    thenType?: "QUERY" | "VALUE" | "FIELD";
    thenValue?: any;
}

const QueryBuilderCustomUtils = {
    // SQL Utilities
    formatSql(sql: string): string {
        const finalSql = sql?.replaceAll("A8_NO_OPERATOR", "") || "";
        return finalSql;
    },

    sqlFormatter(whenData: { caseList: CaseItem[]; elseSql: string; }): string {
        let sqlStr = "CASE";
        sqlStr += whenData.caseList
            .map(
                (caseItem) =>
                    `\n\tWHEN ${caseItem.whenSql || ""} \n\t\t THEN ${caseItem.thenSql}`
            )
            .join("\n");
        sqlStr += `\n\n\tELSE ${whenData.elseSql} \nEND`;
        return sqlStr;
    },

    getSql(jsonData: {
        elseValue: string;
        elseQuery: JsonTree;
        elseType: any; caseList?: CaseItem[]; elseSql?: string; query?: JsonGroup,

    }, config: Config): string {
        if (jsonData.caseList) {
            jsonData.caseList.forEach((caseItem) => {
                caseItem.whenSql = this.formatSql(
                    Utils.sqlFormat(Utils.loadTree(caseItem.when), config)
                );
                if (caseItem.thenQuery) {
                    switch (caseItem.thenType) {
                        case "QUERY":
                            caseItem.thenSql = this.formatSql(
                                Utils.sqlFormat(Utils.loadTree(caseItem.thenQuery), config)
                            );
                            break;
                        case "VALUE":
                            caseItem.thenSql = caseItem.thenValue;
                            break;
                        case "FIELD":
                            caseItem.thenSql = caseItem.thenValue;
                            break;
                    }
                }
            });
            switch (jsonData.elseType) {
                case "QUERY":
                    jsonData.elseSql = this.formatSql(
                        Utils.sqlFormat(Utils.loadTree(jsonData.elseQuery), config)
                    );
                    break;
                case "VALUE":
                    jsonData.elseSql = jsonData.elseValue;
                    break;
                case "FIELD":
                    jsonData.elseSql = jsonData.elseValue;
                    break;
            }
            return this.sqlFormatter(jsonData);
        } else {
            return this.formatSql(
                Utils.sqlFormat(Utils.loadTree(jsonData.query), config)
            );
        }
    },

    // JSON Logic Utilities
    jsonLogicRemoveUnwanted(jsonLogicObj: any): any {
        if (typeof jsonLogicObj === "object") {
            if (jsonLogicObj.and) {
                if (jsonLogicObj.and.length === 1) {
                    jsonLogicObj = jsonLogicObj.and[0];
                    jsonLogicObj =
                        QueryBuilderCustomUtils.jsonLogicRemoveUnwanted(jsonLogicObj);
                }
            } else {
                Object.keys(jsonLogicObj).forEach((key) => {
                    jsonLogicObj[key] = QueryBuilderCustomUtils.jsonLogicRemoveUnwanted(
                        jsonLogicObj[key]
                    );
                });
            }
        }
        if (Array.isArray(jsonLogicObj)) {
            jsonLogicObj.forEach((item) => {
                item = QueryBuilderCustomUtils.jsonLogicRemoveUnwanted(item);
            });
        }
        return jsonLogicObj;
    },

    removeSingleArr(jsonLogicObj: any): any {
        if (Array.isArray(jsonLogicObj)) {
            if (jsonLogicObj.length === 1) {
                jsonLogicObj = jsonLogicObj[0];
            } else {
                jsonLogicObj.forEach((item) => {
                    item = QueryBuilderCustomUtils.removeSingleArr(item);
                });
            }
        }
        if (typeof jsonLogicObj === "object") {
            Object.keys(jsonLogicObj).forEach((key) => {
                jsonLogicObj[key] = QueryBuilderCustomUtils.removeSingleArr(
                    jsonLogicObj[key]
                );
            });
        }
        return jsonLogicObj;
    },

    jsonLogicFormatter(whenData: any): any {
        if (whenData.caseList) {
            var orignalJsonLogic = {
                if: [],
            };
            let jsonLogicObj = {
                if: [],
            };
            orignalJsonLogic = jsonLogicObj;
            for (let index = 0; index < whenData.caseList.length; index++) {
                const caseItem = whenData.caseList[index];
                if (caseItem.whenJsonLogic?.errors?.length) {
                    throw caseItem.whenJsonLogic.errors;
                }
                if (caseItem.thenJsonLogic?.errors?.length) {
                    throw caseItem.thenJsonLogic.errors;
                }
                jsonLogicObj.if[0] = [caseItem.whenJsonLogic.logic];
                jsonLogicObj.if[1] = [caseItem.thenJsonLogic.logic];
                if (whenData.caseList[index + 1]) {
                    jsonLogicObj.if[2] = {
                        if: [],
                    };
                    jsonLogicObj = jsonLogicObj.if[2];
                }
            }
            if (whenData.elseJsonLogic?.errors?.length) {
                throw whenData.elseJsonLogic.errors;
            }
            jsonLogicObj.if[2] = whenData.elseJsonLogic.logic;
            return orignalJsonLogic;
        }
    },

    getJsonLogic(jsonData:any, config: Config): JsonLogicTree {

        let jsonLogicObj:any = {};
        if (jsonData.caseList) {
            jsonData.caseList.forEach((caseItem) => {
                caseItem.whenJsonLogic = Utils.jsonLogicFormat(
                    Utils.loadTree(caseItem.when),
                    config
                );

                if (caseItem.thenQuery) {
                    switch (caseItem.thenType) {
                        case "QUERY":
                            caseItem.thenJsonLogic = Utils.jsonLogicFormat(
                                Utils.loadTree(caseItem.thenQuery),
                                config
                            );

                            break;
                        case "VALUE":
                            caseItem.thenJsonLogic = {logic:caseItem.thenValue};
                            break;
                        case "FIELD":
                            caseItem.thenJsonLogic = {logic:{var:caseItem.thenValue}};
                            break;

                        default:
                            break;
                    }
                }
            });
            switch (jsonData.elseType) {
                case "QUERY":
                    jsonData.elseJsonLogic = Utils.jsonLogicFormat(
                        Utils.loadTree(jsonData.elseQuery),
                        config
                    );

                    break;
                case "VALUE":
                    jsonData.elseJsonLogic = {logic:jsonData.elseValue};
                    break;
                case "FIELD":
                    jsonData.elseJsonLogic = {logic:{var:jsonData.elseValue}};
                    break;

                default:
                    break;
            }
            jsonLogicObj = QueryBuilderCustomUtils.jsonLogicFormatter(jsonData);
        } else {
            jsonLogicObj = Utils.jsonLogicFormat(
                Utils.loadTree(jsonData.query),
                config
            );
            jsonLogicObj = jsonLogicObj.logic;
        }
        QueryBuilderCustomUtils.jsonLogicRemoveUnwanted(jsonLogicObj);
        jsonLogicObj = QueryBuilderCustomUtils.removeSingleArr(jsonLogicObj);
        return jsonLogicObj;
    },


    // Other Utilities
    createFields(columns: any[]): any {
        const finalFields = {};
        columns.forEach((column) => {
            const finalColumnObj: any = {
                type: "",
                label: column.name || "",
                valueSources: ["value"],
            };
            switch (column.dataType) {
                case "string":
                    finalColumnObj.type = "text";
                    break;

                default:
                    finalColumnObj.type = column.dataType;
                    break;
            }
            if (column.columnType === "master") {
                finalColumnObj.type = "select";
                finalColumnObj.fieldSettings = {
                    listValues: column.master,
                };
            }

            finalFields[column.name] = finalColumnObj;
        });
        return finalFields;
    },

    generateFunction(funcName: string, type: string, args = 1): any {
        const funcObj: any = {
            label: funcName,
            sqlFunc: funcName,
            returnType: type,
            args: {
                str: {
                    label: type,
                    type,
                    valueSources: ["value", "field", "func"],
                },
            },
            allowSelfNesting: true,
            jsonLogic: funcName,
        };
        if (args) {
            const finalArgs = {};
            for (let index = 0; index < args; index += 1) {
                const element = `${type}${index + 1}`;
                finalArgs[element] = {
                    label: element,
                    type,
                    valueSources: ["value", "field", "func"],
                };
            }

            funcObj.args = finalArgs;
        }
        return funcObj;
    },

};

export default QueryBuilderCustomUtils;
