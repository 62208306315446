const sqlFormatter = (whenData, elseObj) => {
  let sqlStr = 'CASE';
  sqlStr += whenData.caseList
    .map(
      (caseItem) =>
        `\n\tWHEN ${caseItem.whenValue || ''} \n\t\t THEN ${caseItem.thenValue}`
    )
    .join('\n');
  sqlStr += `\n\n\tELSE ${elseObj.value} \nEND`;
  return sqlStr;
};
export default sqlFormatter;
