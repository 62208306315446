/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable max-lines */
import merge from 'lodash/merge';
import {
  BasicFuncs,
  BasicConfig,
  // types:
  Operators,
  Fields,
  Types,
  Conjunctions,
  LocaleSettings,
  OperatorProximity,
  Funcs,
  Settings,
  Widgets,
  Config,
} from '@react-awesome-query-builder/ui';
import ru_RU from 'antd/es/locale/ru_RU';
import { AntdConfig } from '@react-awesome-query-builder/antd';
import A8MultipleSelect from './custom_widget/A8MultipleSelect';
import sqlBuilderFunctions from './functions/sqlBuilderFunctions';
import ONLY_FIELDS from './functions/number/ONLY_FIELDS';
import GREATEST from './functions/number/GREATEST';
import LEAST from './functions/number/LEAST';
import LENGTH from './functions/string/LENGTH';
import STRING_ONLY_FIELDS from './functions/string/STRING_ONLY_FIELDS';

const skinToConfig: Record<string, Config> = {
  vanilla: BasicConfig,
  antd: AntdConfig,
};

export default (skin: string, customFields: Fields) => {
  const InitialConfig = skinToConfig[skin] as BasicConfig;

  const conjunctions: Conjunctions = {
    ...InitialConfig.conjunctions,
  };

  const proximity: OperatorProximity = {
    ...InitialConfig.operators.proximity,
    valueLabels: [
      { label: 'Word 1', placeholder: 'Enter first word' },
      { label: 'Word 2', placeholder: 'Enter second word' },
    ],
    textSeparators: [
      // 'Word 1',
      // 'Word 2'
    ],
    options: {
      ...InitialConfig.operators.proximity.options,
      optionLabel: 'Near', // label on top of "near" selectbox (for config.settings.showLabels==true)
      optionTextBefore: 'Near', // label before "near" selectbox (for config.settings.showLabels==false)
      optionPlaceholder: 'Select words between', // placeholder for "near" selectbox
      minProximity: 2,
      maxProximity: 10,
      defaults: {
        proximity: 2,
      },
      customProps: {},
    },
  };
  const operators: Operators = {
    ...InitialConfig.operators,
    // examples of  overriding
    proximity,
    between: {
      ...InitialConfig.operators.between,
      valueLabels: ['Value from', 'Value to'],
      textSeparators: ['from', 'to'],
    },
    a8NoOperator: {
      label: 'No Operator',
      reversedOp: 'a8NoOperator',
      cardinality: 0,
      labelForFormat: 'A8_NO_OPERATOR',
      sqlOp: 'A8_NO_OPERATOR',
      formatOp: function formatOp(
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) {
        return ''.concat(field, 'A8_NO_OPERATOR');
      },
      jsonLogic: function (
        field,
        op,
        value,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) {
        return {
          ...field,
        };
      },
      sqlFormatOp: function spelFormatOp(
        field,
        op,
        values,
        valueSrc,
        valueTypes,
        opDef,
        operatorOptions,
        fieldDef
      ) {
        return ''.concat(field, 'A8_NO_OPERATOR');
      },
    },
  };

  const widgets: Widgets = {
    ...InitialConfig.widgets,
    // examples of overriding
    text: {
      ...InitialConfig.widgets.text,
    },
    textarea: {
      ...InitialConfig.widgets.textarea,
      maxRows: 3,
    },
    rangeslider: {
      ...InitialConfig.widgets.rangeslider,
    },
    date: {
      ...InitialConfig.widgets.date,
      dateFormat: 'DD/MM/YYYY',
      valueFormat: 'YYYY-MM-DD',
    },
    time: {
      ...InitialConfig.widgets.time,
      timeFormat: 'HH:mm',
      valueFormat: 'HH:mm:ss',
    },
    datetime: {
      ...InitialConfig.widgets.datetime,
      timeFormat: 'HH:mm',
      dateFormat: 'DD/MM/YYYY',
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
    },
    func: {
      ...InitialConfig.widgets.func,
      customProps: {
        showSearch: true,
      },
    },
    select: {
      ...InitialConfig.widgets.select,
    },
    multiselect: {
      ...InitialConfig.widgets.multiselect,
      valueSrc: 'value',
      customProps: {
        // showCheckboxes: false,
        width: '200px',
        input: {
          width: '100px',
        },
      },
    },
    a8fields: {
      ...InitialConfig.widgets.text,
      type: 'a8fields',
      factory: (props) => <A8MultipleSelect {...props} />,
      formatValue: (val, _fieldDef, _wgtDef, isForDisplay) => {
        console.log('nilesh format', val, _fieldDef, _wgtDef, isForDisplay);
        return isForDisplay ? val.toString() : JSON.stringify(val);
      },
    },
    a8multiselect: {
      ...InitialConfig.widgets.text,
      type: 'a8multiselect',
      factory: (props) => <A8MultipleSelect {...props} mode="tags" />,
      formatValue: (val, _fieldDef, _wgtDef, isForDisplay) => {
        console.log('nilesh format', val, _fieldDef, _wgtDef, isForDisplay);
        return isForDisplay ? val.toString() : JSON.stringify(val);
      },
    },
    treeselect: {
      ...InitialConfig.widgets.treeselect,
      customProps: {
        showSearch: true,
      },
    },
  };

  const types: Types = {
    ...InitialConfig.types,
    // examples of  overriding
    number: {
      ...InitialConfig.types.number,
      widgets: {
        number: {
          operators: [
            'a8NoOperator',
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'between',
            'not_between',
            'is_null',
            'is_not_null',
          ],
        },
      },
    },
    text: {
      ...InitialConfig.types.text,
      widgets: {
        text: {
          operators: [
            'a8NoOperator',
            'proximity',
            'equal',
            'not_equal',
            'less',
            'less_or_equal',
            'greater',
            'greater_or_equal',
            'like',
            'not_like',
            'starts_with',
            'ends_with',
            'between',
            'not_between',
            'is_null',
            'is_not_null',
            'is_empty',
            'is_not_empty',
            'select_equals',
            'select_not_equals',
            'select_any_in',
            'select_not_any_in',
            'multiselect_contains',
            'multiselect_not_contains',
            'multiselect_equals',
            'multiselect_not_equals',
          ],
        },
      },
    },
    a8fields: {
      valueSources: ['value'],
      widgets: {
        a8fields: {
          operators: ['a8NoOperator'],
        },
      },
    },
    a8multiselect: {
      ...InitialConfig.types.text,
      widgets: {
        a8multiselect: {
          operators: ['a8NoOperator'],
        },
      },
    },
    a8text: {
      ...InitialConfig.types.text,
      widgets: {
        text: {
          operators: ['a8NoOperator'],
        },
      },
    },
    boolean: merge({}, InitialConfig.types.boolean, {
      widgets: {
        boolean: {
          widgetProps: {
            hideOperator: true,
            operatorInlineLabel: 'is',
          },
          opProps: {
            equal: {
              label: 'is',
            },
            not_equal: {
              label: 'is not',
            },
          },
        },
      },
    }),
  };
  const localeSettings: LocaleSettings = {
    locale: {
      moment: 'ru',
      antd: ru_RU,
    },
    valueLabel: 'Value',
    valuePlaceholder: 'Value',
    fieldLabel: 'Field',
    operatorLabel: 'Operator',
    funcLabel: 'Function',
    fieldPlaceholder: 'Select field',
    funcPlaceholder: 'Select function',
    operatorPlaceholder: 'Select operator',
    lockLabel: 'Lock',
    lockedLabel: 'Locked',
    deleteLabel: undefined,
    addGroupLabel: 'Add group',
    addRuleLabel: 'Add rule',
    addSubRuleLabel: 'Add sub rule',
    delGroupLabel: undefined,
    notLabel: 'Not',
    fieldSourcesPopupTitle: 'Select source',
    valueSourcesPopupTitle: 'Select value source',
    removeRuleConfirmOptions: {
      title: 'Are you sure delete this rule?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
    },
    removeGroupConfirmOptions: {
      title: 'Are you sure delete this group?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
    },
  };

  const settings: Settings = {
    ...InitialConfig.settings,
    ...localeSettings,

    defaultSliderWidth: '200px',
    defaultSelectWidth: '200px',
    defaultSearchWidth: '100px',
    defaultMaxRows: 5,

    // Example of how to correctly configure default LHS funtion with args:
    // defaultField: {
    //   func: "date.RELATIVE_DATETIME",
    //   args: {
    //     date: {
    //       value: {func: "date.NOW", args: {}},
    //       valueSrc: "func"
    //     },
    //     op: {
    //       value: "plus",
    //       valueSrc: "value"
    //     },
    //     dim: {
    //       value: "day",
    //       valueSrc: "value"
    //     },
    //     val: {
    //       value: 1,
    //       valueSrc: "value"
    //     }
    //   }
    // },

    valueSourcesInfo: {
      value: {
        label: 'Value',
      },
      field: {
        label: 'Field',
        widget: 'field',
      },
      func: {
        label: 'Function',
        widget: 'func',
      },
    },
    fieldSources: ['field', 'func'],
    keepInputOnChangeFieldSrc: true,
    // canReorder: true,
    // canRegroup: true,
    // showLock: true,
    // showNot: true,
    // showLabels: true,
    maxNesting: 5,
    canLeaveEmptyGroup: true,
    shouldCreateEmptyGroup: false,
    showErrorMessage: true,
    removeEmptyGroupsOnLoad: false,
    removeIncompleteRulesOnLoad: false,
    customFieldSelectProps: {
      showSearch: true,
    },
    // renderField: (props) => <FieldCascader {...props} />,
    // renderOperator: (props) => <FieldDropdown {...props} />,
    // renderFunc: (props) => <FieldSelect {...props} />,
    // maxNumberOfRules: 10 // number of rules can be added to the query builder

    // defaultField: "user.firstName",
    // defaultOperator: "starts_with",

    // canCompareFieldWithField: (leftField, leftFieldConfig, rightField, rightFieldConfig, op) => {
    //   if (leftField === 'slider' && rightField === 'results.score') {
    //     return false;
    //   }
    //   return true;
    // }
  };

  /// ///////////////////////////////////////////////////////////////////

  const fields: Fields = {
    ...customFields,
  };

  console.log('BasicFuncs.UPPER', BasicFuncs.UPPER);
  const numCustomFields = Object.fromEntries(
    Object.entries(customFields).filter(([key, value]) => value.type === "number")
);

console.log(customFields,'customFields')
  /// ///////////////////////////////////////////////////////////////////
  const funcs: Funcs = {
    // ...BasicFuncs
    string: {
      type: '!struct',
      label: 'String',
      subfields: {
        ...sqlBuilderFunctions.string,
        LENGTH: LENGTH(customFields),
        STRING_ONLY_FIELDS: STRING_ONLY_FIELDS(customFields),
        CONCAT: merge({}, BasicFuncs.UPPER, {
          tooltip: 'Convert to upper case',
          allowSelfNesting: true,
          label: 'Concat',
          mongoFunc: '$toConcat',
          returnType: 'text',
          sqlFunc: 'CONCAT',
          jsonLogic: (formattedArgs) => {
            const finalJsonLogicObject = {
              CONCAT: [],
            };
            finalJsonLogicObject.CONCAT.push(formattedArgs.str);
            finalJsonLogicObject.CONCAT.push(formattedArgs.str2);
            return finalJsonLogicObject;
          },
          args: {
            str: {
              type: 'text',
              valueSources: ['value', 'field', 'func'],
            },
            str2: {
              type: 'text',
              valueSources: ['value', 'field', 'func'],
            },
          },
          sqlFormatFunc: (formattedArgs) => {
            console.log('nilesh concat formattedArgs', formattedArgs);
            return `CONCAT(${formattedArgs.str}, ${formattedArgs.str2})`;
          },
        }),
      },
    },

    date: {
      type: '!struct',
      label: 'Date',
      subfields: {
        NOW: BasicFuncs.NOW,
        RELATIVE_DATETIME: merge({}, BasicFuncs.RELATIVE_DATETIME, {
          args: {
            date: {
              defaultValue: { func: 'date.NOW', args: [] },
            },
          },
        }),
      },
    },
    number: {
      type: '!struct',
      label: 'Number',
      subfields: {
        ...sqlBuilderFunctions.number,
        LINEAR_REGRESSION: BasicFuncs.LINEAR_REGRESSION,
        ONLY_FIELDS: ONLY_FIELDS(customFields),
        GREATEST: GREATEST(numCustomFields),
        LEAST: LEAST(numCustomFields),
      },
    },
    select: {
      type: '!struct',
      label: 'Select',
      subfields: {
        toSelectString: {
          returnType: 'text',
          label: 'ONLY FIELDS',
          mongoFunc: '$toString',
          args: {
            strList: {
              type: 'a8multiselect',
              listValues: Object.keys(customFields),
            },
          },
          jsonLogic: (formattedArgs) => {
            return ( formattedArgs.strList
              .replaceAll("'", "")
              .split(",")
              .map((item) => {
                if (Object.keys(customFields).indexOf(item) > -1) {
                  return { var: item };
                }
                if (/^\d+(\.\d+)?$/.test(item)) {
                  return `'${item}'`;
                }
                return `'${item}'`;
              }));
          },
          sqlFormatFunc: (formattedArgs) => {
            const finalData = formattedArgs.strList
              .replaceAll("'", '')
              .split(',')
              .map((item) => {
                if (Object.keys(customFields).indexOf(item) > -1) {
                  return item;
                }
                if (/^\d+(\.\d+)?$/.test(item)) {
                  return `'${item}'`;
                }
                return `'${item}'`;
              });
            return `(${finalData.join(',')})`;
          },
        },
      },
    },
  };

  const { ctx } = InitialConfig;

  const config: Config = {
    ctx,
    conjunctions,
    operators,
    widgets,
    types,
    settings,
    fields,
    funcs,
  };

  return config;
};
