const ruleDashboard = 'dashboard';
const login = 'login';
const error = 'error';
const rulePlans = 'rule_plan';
const ruleGroups = 'rule_group';
const rules = 'rules';
const program = 'program';
const frequency = 'frequency';
const workflow = 'workflow';
const ruleManagement = 'rule';
const incentiveProgram = 'incentive_program';
const unifiedSourceMaster = 'unified_source_master';
const profile = 'profile';

const resourceName = {
  ruleDashboard,
  login,
  error,
  rulePlans,
  ruleGroups,
  rules,
  program,
  frequency,
  workflow,
  ruleManagement,
  incentiveProgram,
  unifiedSourceMaster,
  profile,
};

export default resourceName;
