// Project Imports
import openNotificationWithIcon from 'core/utils/notification';

const updateNotifications = (notificationMessages, newMessage) => {
  const updatedMessages = [...notificationMessages, newMessage];
  openNotificationWithIcon(
    newMessage.status,
    newMessage?.eventName,
    newMessage?.message
  );

  localStorage.setItem('notifications', JSON.stringify(updatedMessages));
  return updatedMessages;
};

const fetchNotificatons = async (
  setNotificationMessages,
  userDetails,
  socketInstance,
  notificationMessages
): Promise<void> => {
  console.log(userDetails);
  try {
    socketInstance.on('input_data_file_upload', (data) => {
      const newMessage = {
        title: data?.eventName,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
      };
      const updatedMessages = updateNotifications(
        notificationMessages,
        newMessage
      );
      if (data?.userId === userDetails?.id) {
        setNotificationMessages(updatedMessages);
      }
    });
    socketInstance.on('master_data_file_upload', (data) => {
      const newMessage = {
        title: data?.eventName,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
      };
      const updatedMessages = updateNotifications(
        notificationMessages,
        newMessage
      );
      if (data?.userId === userDetails?.id) {
        setNotificationMessages(updatedMessages);
      }
    });
    socketInstance.on('initiate_unification', (data) => {
      const newMessage = {
        title: data?.eventName,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
      };
      const updatedMessages = updateNotifications(
        notificationMessages,
        newMessage
      );
      if (data?.userId === userDetails?.id) {
        setNotificationMessages(updatedMessages);
      }
    });
    socketInstance.on('start_payout', (data) => {
      const newMessage = {
        title: data?.eventName,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
      };
      const updatedMessages = updateNotifications(
        notificationMessages,
        newMessage
      );
      if (data?.userId === userDetails?.id) {
        setNotificationMessages(updatedMessages);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export default fetchNotificatons;
