/* eslint-disable @typescript-eslint/naming-convention */
interface InterfaceSqlQueryBuilderConstant {
  MODE: {
    SQL: 'sql';
    SINGLE: 'single';
  };
}
const SQL_QUERY_BUILDER_CONSTANT: InterfaceSqlQueryBuilderConstant = {
  MODE: {
    SQL: 'sql',
    SINGLE: 'single',
  },
};
export default SQL_QUERY_BUILDER_CONSTANT;
