// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';

const RulePlansList = lazy(() => import('pages/rulePlans/rulePlansList'));
const RulePlanStepper = lazy(
  () => import('pages/rulesDashboard/rulePlanDashboard')
);
const RulePlansRoute = (
  <Route path={routeName.rulePlans}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.RULE_PLANS_VIEW,
            PrivateRouteConst.SELF_RULE_PLANS_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <RulePlansList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_RULE_PLANS}>
          <Suspense fallback={<Loading />}>
            <RulePlanStepper type="Create" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.RULE_PLANS_EDIT,
            PrivateRouteConst.SELF_RULE_PLANS_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <RulePlanStepper type="Edit" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.RULE_PLANS_VIEW,
            PrivateRouteConst.SELF_RULE_PLANS_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <RulePlanStepper type="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default RulePlansRoute;
