const dashboard = '/dashboard';
const login = '/login';
const error = '/error';
const rulePlans = 'rule_plan';
const ruleGroups = 'rule_group';
const rules = 'rules';
const program = 'program';
const frequency = 'frequency';
const workflow = 'workflow';
const ruleManagement = 'rule';
const profile = '/profile';

const routeName = {
  dashboard,
  login,
  error,
  rulePlans,
  ruleGroups,
  rules,
  program,
  frequency,
  workflow,
  ruleManagement,
  profile,
};

export default routeName;
