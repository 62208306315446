// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const RulesDashboardResource: ResourceItem = {
  name: resourceName.ruleDashboard,
  identifier: resourceName.ruleDashboard,
  list: `${resourceName.ruleDashboard}`,
  options: { label: 'Dashboard' },
};

export default RulesDashboardResource;
