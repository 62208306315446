/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const LEAST = (customFields: any): FuncOrGroup => ({
  label: 'LEAST',
  sqlFunc: 'LEAST',
  returnType: 'number',
  args: {
    number1: {
      label: 'number1',
      type: 'a8multiselect',
      valueSources: ['value'],
      listValues: Object.keys(customFields),
    },
  },
  jsonLogic: "min",
  allowSelfNesting: true,
  formatFunc: (formattedArgs) =>
    `LEAST(${formattedArgs.number1.replaceAll("'", '')})`,
  sqlFormatFunc: (formattedArgs) =>
    `LEAST(${formattedArgs.number1.replaceAll("'", '')})`,
});

export default LEAST;
