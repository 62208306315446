/* eslint-disable no-debugger */
import { Select } from 'antd/lib';
import { useEffect, useState } from 'react';

const A8MultipleSelect = (props) => {
  console.log('nilesh props', props);
  const [a8SelectValue, setA8SelectValue] = useState([]);
  useEffect(() => {
    switch (typeof props?.value) {
      case 'object':
        if (Array.isArray(props?.value)) {
          setA8SelectValue(props?.value);
        } else if (props?.value?.toArray) {
          setA8SelectValue(props?.value?.toArray());
        }

        break;
      case 'string':
        setA8SelectValue(props?.value.split(','));
        break;
      default:
        break;
    }
  }, [props?.value]);
  const extraProps: any = {};
  if (props?.mode) {
    extraProps.mode = props?.mode;
  }
  return (
    <Select
      value={a8SelectValue}
      onChange={(data) => {
        if (Array.isArray(data)) {
          props?.setValue(data.join(','));
        } else {
          props?.setValue(data);
        }
      }}
      allowClear
      placeholder="Select"
      options={
        props?.listValues?.map((item) => ({ value: item, label: item })) || []
      }
      style={{ width: '200px' }}
      {...extraProps}
    />
  );
};

export default A8MultipleSelect;
