/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const LENGTH = (customFields: any): FuncOrGroup => ({
  label: 'LENGTH',
  sqlFunc: 'LENGTH',
  returnType: 'number',
  jsonLogic: "LENGTH",
  args: {
    number1: {
      label: 'fieldName',
      type: 'text',
      valueSources: ['value', 'field', 'func'],
      listValues: Object.keys(customFields),
    },
  },
  allowSelfNesting: true,
});

export default LENGTH;
