/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const GREATEST = (customFields: any): FuncOrGroup => ({
  label: 'GREATEST',
  sqlFunc: 'GREATEST',
  returnType: 'number',
 
  args: {
    number1: {
      label: 'number1',
      type: 'a8multiselect',
      valueSources: ['value'],
      listValues: Object.keys(customFields),
      allowCustomValues:true
    },
  },
  allowSelfNesting: true,
  jsonLogic: (formattedArgs) => {
    let options = (formattedArgs.number1
      .replaceAll("'", "")
      .split(",")
      .map((item) => {
        if (Object.keys(customFields).indexOf(item) > -1) {
          return { var: item };
        }
        if (/^\d+(\.\d+)?$/.test(item)) {
          return item;
        }
        return `'${item}'`;
      }));
    return {
      max: options,
    };
  },
  formatFunc: (formattedArgs) =>
    `GREATEST(${formattedArgs.number1.replaceAll("'", '')})`,
  sqlFormatFunc: (formattedArgs) =>
    `GREATEST(${formattedArgs.number1.replaceAll("'", '')})`,
});

export default GREATEST;
