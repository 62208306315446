// Project Imports
import RulesDashboardResource from 'core/resources/RulesDashboard';
import RulePlansResource from 'core/resources/RulePlans';
// import RuleGroupsResource from 'core/resources/RuleGroups';
// import RulesResource from 'core/resources/Rules';
import ProgramResource from 'core/resources/Program';
import FrequencyResource from 'core/resources/Frequency';
import WorkflowResource from 'core/resources/Workflow';

const resources = [
  RulesDashboardResource,
  RulePlansResource,
  // RuleGroupsResource,
  // RulesResource,
  ProgramResource,
  FrequencyResource,
  WorkflowResource,
];

export default resources;
