/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import '@react-awesome-query-builder/antd/css/styles.css';
import TextArea from 'antd/lib/input/TextArea';
import SQLQueryBuilder from './sqlQueryBuilder';
import dummyJson from './dummy';
import InterfaceSqlBuilderConfig from './sqlQueryBuilder/interfaces/InterfaceSqlBuilderConfig';
import SQL_QUERY_BUILDER_CONSTANT from './sqlQueryBuilder/constant/SQL_QUERY_BUILDER_CONSTANT';

interface QueryBuilderProps {
  configFields?: InterfaceSqlBuilderConfig;
  queryValue?: any;
  setExpression?: any;
  expressionValue?: any;
  queryResult?: boolean;
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({
  configFields,
  queryValue,
  setExpression,
  expressionValue,
  queryResult,
}) => {
  const [sqlValue, setSqlValue] = useState(queryValue || '');
  const [value, setValue] = useState(dummyJson);
  const onSave = (data: any) => {
    setSqlValue(data.sql);
    setValue(data.json);
    if (setExpression) {
      setExpression(data.json);
    }
  };

  console.log(expressionValue, 'expressionValue');

  const sqlBuilderConfig: InterfaceSqlBuilderConfig = {
    mode: SQL_QUERY_BUILDER_CONSTANT.MODE.SQL,
    columns: [
      {
        name: 'FINAL_RESOLUTION',
        dataType: 'string',
        columnType: 'master',
        master: ['NORM', 'RB'],
      },
      {
        name: 'BOM_BKT',
        dataType: 'string',
        columnType: 'master',
        master: ['0', 'X', '1', '2', '3'],
      },
      {
        name: 'PV_FINAL_PRODUCT_NEW',
        dataType: 'string',
        columnType: 'master',
        master: ['PL', 'BL', 'MBL', 'CCOD_PL', 'CCOD_BL'],
      },
      { name: 'POS', dataType: 'number', columnType: 'nomral' },
    ],
  };
  return (
    <div>
      <SQLQueryBuilder
        onSave={onSave}
        value={expressionValue || value}
        config={configFields || sqlBuilderConfig}
      />
      {queryResult ? (
        <div className="query-builder-result">
          <div>
            <TextArea value={sqlValue} rows={10} />
          </div>
          <h3>Json Logic</h3>
          <pre>{JSON.stringify(value.jsonLogic, null, 2)}</pre>
          <h3>Json</h3>
          <pre>{JSON.stringify(value, null, 2)}</pre>
        </div>
      ) : null}
    </div>
  );
};

export default QueryBuilder;
