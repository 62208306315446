// Package Imports
import { PartitionOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const WorkflowResource: ResourceItem = {
  name: resourceName.workflow,
  identifier: ModuleIdentifierConst.WORKFLOW,
  list: `${resourceName.workflow}`,
  create: `${resourceName.workflow}/create`,
  edit: `${resourceName.workflow}/edit/:id`,
  show: `${resourceName.workflow}/show/:id`,
  options: { label: 'Workflow', icon: <PartitionOutlined /> },
};

export default WorkflowResource;
