/* eslint-disable import/extensions */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import TypeAndQueryCard from '../TypeAndQueryCard';
import WhenConditionCard from '../whenConditionCard';
import SQLQueryBuilderCustomUtils from '../custom_utils/SQLQueryBuilderCustomUtils';
import QueryBuilderCustomUtils from '../custom_utils/QueryBuilderCustomUtils';
import loadConfig from "../config";
const SQLMode = ({ value, fields, onSave }) => {
  const [whenData, setWhenData] = useState<any>({
    caseList: [{ when: '', then: '' }],
  });
  const [elseObj, setElseObj] = useState<any>({
    type: value.elseType,
    value: value.elseValue,
    query: value.elseQuery,
  });
  const onTypeSave = (data) => {
    setElseObj(data);
  };
  const onSaveHandler = () => {
    onSave({
      sql: SQLQueryBuilderCustomUtils.sqlFormatter(whenData, elseObj),
      json: {
        ...whenData,
        elseType: elseObj.type,
        elseValue: elseObj.value,
        elseQuery: elseObj.query,
      },
    });
  };
  const onWhenSave = (data, index) => {
    whenData.caseList[index] = data;
  };
  const addWhenConditionHandler = () => {
    setWhenData({
      ...whenData,
      caseList: [...whenData.caseList, { when: '', then: '' }],
    });
  };

  const [loading, setLoading] = useState(false);

  const onDeleteHandler = (index) => {
    const finalData = {
      ...whenData,
      caseList: [...whenData.caseList.filter((_, i) => i !== index)],
    };
    console.log(finalData);
    setWhenData({ ...finalData });
    setLoading(true);
  };

  useEffect(() => {
    if (loading) setLoading(false);
  }, [loading]);
  useEffect(() => {
    const finalWhenData = { ...value };
    if (value?.caseList?.length === 0) {
      finalWhenData.caseList = [{ when: '', then: '' }];
    }
    setWhenData({ ...finalWhenData });
    setLoading(true);
  }, [value]);


  const showModal = () => {
    Modal.info({
      title: '',
      width: 1000,
      content: (
        <div>
          <pre>{SQLQueryBuilderCustomUtils.sqlFormatter(whenData, elseObj)}</pre>
        </div>
      ),
      onOk() { },
    });
  };

  const showJsonLogicModal = () => {
    const loadedConfig = loadConfig('antd', fields);
    let jsonLogic = QueryBuilderCustomUtils.getJsonLogic({
      ...whenData,
      elseType: elseObj.type,
      elseValue: elseObj.value,
      elseQuery: elseObj.query,
    }, loadedConfig);
    Modal.info({
      title: '',
      width: 1000,
      content: (
        <div >
          <pre>{JSON.stringify(jsonLogic, null, 2)}</pre>
        </div>
      ),
      onOk() { },
    });
  };



  return (
    <>
      <div>
        <div className="flex justify-end mulitple-option-query-btn-group query-btn-group">
          <Button
            size='middle'
            type="default"
            className="mx-2"
            onClick={addWhenConditionHandler}
          >
            {' '}
            + Add When Condition
          </Button>
          <Button size='middle' className="mx-2" type="default" key="save" onClick={onSaveHandler}>
            Validate
          </Button>
          <Button size='middle' type='default' className="mx-2" onClick={showModal}>
            Show Query
          </Button>
          <Button size='middle' className='ml-2' type='default' onClick={showJsonLogicModal}>
            Show JSON Logic
          </Button>
        </div>
      </div>
      <div className="multiple-option-query-builder">
        Case
        {whenData.caseList.map((caseItem, index) => (
          <div className="multiple-option-main-container-parent" key={index}>
            {loading ? (
              <Spin />
            ) : (
              <div className="main-container" key={index}>
                <span
                  style={{ position: 'absolute', top: '20px', right: '30px' }}
                >
                  {whenData.caseList.length > 1 ? (
                    <DeleteOutlined
                      style={{ color: '#ff4d4f' }}
                      onClick={onDeleteHandler.bind(null, index)}
                    />
                  ) : null}
                </span>
                <div className="when-section-container">
                  <WhenConditionCard
                    fields={fields}
                    onSave={(data) => {
                      onWhenSave(data, index);
                    }}
                    value={{ ...caseItem }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="main-container  half-height">
          <TypeAndQueryCard
            title="Else"
            onSave={onTypeSave}
            data={elseObj}
            fields={fields}
          />
        </div>
      </div>


    </>
  );
};

export default SQLMode;
