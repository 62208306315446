const PrivateRouteConst = {
  FREQUENCY_VIEW: 'allFrequencyView',
  FREQUENCY_EDIT: 'allFrequencyEdit',
  FREQUENCY_DELETE: 'allFrequencyDelete',
  SELF_FREQUENCY_VIEW: 'selfFrequencyView',
  SELF_FREQUENCY_EDIT: 'selfFrequencyEdit',
  SELF_FREQUENCY_DELETE: 'selfFrequencyDelete',
  CREATE_FREQUENCY: 'createFrequency',

  WORKFLOW_VIEW: 'allWorkflowView',
  WORKFLOW_EDIT: 'allWorkflowEdit',
  WORKFLOW_DELETE: 'allWorkflowDelete',
  SELF_WORKFLOW_VIEW: 'selfWorkflowView',
  SELF_WORKFLOW_EDIT: 'selfWorkflowEdit',
  SELF_WORKFLOW_DELETE: 'selfWorkflowDelete',
  CREATE_WORKFLOW: 'createWorkflow',
  CREATE_CONFIG_WORKFLOW: 'createConfigWorkflow',

  RULES_VIEW: 'allRulesView',
  RULES_EDIT: 'allRulesEdit',
  RULES_DELETE: 'allRulesDelete',
  SELF_RULES_VIEW: 'selfRulesView',
  SELF_RULES_EDIT: 'selfRulesEdit',
  SELF_RULES_DELETE: 'selfRulesDelete',
  CREATE_RULE: 'createRule',

  RULE_GROUPS_VIEW: 'allRuleGroupsView',
  RULE_GROUPS_EDIT: 'allRuleGroupsEdit',
  RULE_GROUPS_DELETE: 'allRuleGroupsDelete',
  SELF_RULE_GROUPS_VIEW: 'selfRuleGroupsView',
  SELF_RULE_GROUPS_EDIT: 'selfRuleGroupsEdit',
  SELF_RULE_GROUPS_DELETE: 'selfRuleGroupsDelete',
  CREATE_RULE_GROUPS: 'createRuleGroups',

  RULE_PLANS_VIEW: 'allRulePlansView',
  RULE_PLANS_EDIT: 'allRulePlansEdit',
  RULE_PLANS_DELETE: 'allRulePlansDelete',
  SELF_RULE_PLANS_VIEW: 'selfRulePlansView',
  SELF_RULE_PLANS_EDIT: 'selfRulePlansEdit',
  SELF_RULE_PLANS_DELETE: 'selfRulePlansDelete',
  CREATE_RULE_PLANS: 'createRulePlans',

  CONFIG_PROGRAM_VIEW: 'allConfigProgramView',
  CONFIG_PROGRAM_EDIT: 'allConfigProgramEdit',
  CONFIG_PROGRAM_DELETE: 'allConfigProgramDelete',
  SELF_CONFIG_PROGRAM_VIEW: 'selfConfigProgramView',
  SELF_CONFIG_PROGRAM_EDIT: 'selfConfigProgramEdit',
  SELF_CONFIG_PROGRAM_DELETE: 'selfConfigProgramDelete',
  CREATE_CONFIG_PROGRAM: 'createConfigProgram',
};
export default PrivateRouteConst;
