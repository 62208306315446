/* eslint-disable import/extensions */
import { Row, Col, Select, Input } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import {
  Query,
  Builder,
  Utils as QbUtils,
  Config,
  ImmutableTree,
  BuilderProps,
  JsonTree,
} from '@react-awesome-query-builder/antd';
import loadConfig from './config';
import SQLQueryBuilderCustomUtils from './custom_utils/SQLQueryBuilderCustomUtils';
import InterfaceSqlQueryBuilderState from './interfaces/InterfaceSqlQueryBuilderState';

const TypeAndQueryCard = ({
  data,
  fields,
  onSave,
  title,
  hideType = false,
}) => {
  const emptyInitValue: JsonTree = { id: QbUtils.uuid(), type: 'group' };
  const [cardType, setThenType] = useState(data.type || 'VALUE');
  const [cardValue, setValue] = useState(data.value || '');
  const loadedConfig = loadConfig('antd', fields);

  const initTree: ImmutableTree = QbUtils.loadTree(
    data.query || emptyInitValue
  );
  const [state, setState] = useState<InterfaceSqlQueryBuilderState>({
    tree: initTree,
    config: loadedConfig,
    skin: 'antd',
    spelStr: '',
    spelErrors: [] as Array<string>,
    renderBocks: {
      validation: true,
      jsonlogic: true,
      elasticSearch: true,
      mongo: true,
      jsTree: true,
      spel: true,
      strings: true,
      sql: true,
    },
  });
  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container">
        <div className="query-builder">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  const onChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      setState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config,
      }));
    },
    []
  );
  useEffect(() => {
    let val: any = '';
    switch (cardType) {
      case 'VALUE':
        val = cardValue;
        break;
      case 'QUERY':
        val = SQLQueryBuilderCustomUtils.formatSql(
          QbUtils.sqlFormat(state.tree, state.config)
        );
        break;
      case 'FIELD':
        val = cardValue;
        break;

      default:
        break;
    }
    onSave({
      value: val,
      query: QbUtils.getTree(state.tree),
      sql: SQLQueryBuilderCustomUtils.formatSql(
        QbUtils.sqlFormat(state.tree, state.config)
      ),
      jsonLogic: QbUtils.jsonLogicFormat(state.tree, state.config),
      type: cardType,
    });
  }, [cardValue, state.tree, state.config]);
  useEffect(() => {
    if (hideType) {
      setThenType('QUERY');
    }
  }, [hideType]);
  return (
    <div className="when-container">
      <span>{title}</span>
      <Row gutter={16}>
        {hideType ? null : (
          <Col span={4}>
            <Select
              className="w-full"
              options={['FIELD', 'VALUE', 'QUERY'].map((label) => ({
                label,
                value: label,
              }))}
              defaultValue={cardType}
              onChange={(val) => {
                setThenType(val);
              }}
            />
          </Col>
        )}

        <Col span={hideType ? 24 : 20}>
          {cardType === 'FIELD' ? (
            <Select
              className="w-full"
              options={Object.keys(fields).map((key) => ({
                label: key,
                value: key,
              }))}
              defaultValue={cardValue}
              onChange={(val) => {
                setValue(val);
              }}
            />
          ) : null}
          {cardType === 'VALUE' ? (
            <Input
              onChange={(e) => setValue(e.target.value)}
              defaultValue={cardValue}
            />
          ) : null}
          {cardType === 'QUERY' ? (
            <Query
              {...state.config}
              value={state.tree}
              onChange={onChange}
              renderBuilder={renderBuilder}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default TypeAndQueryCard;
