/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const ONLY_FIELDS = (customFields: any): FuncOrGroup => ({
  label: 'ONLY_FIELDS',
  sqlFunc: 'ONLY_FIELDS',
  returnType: 'number',
  args: {
    number1: {
      label: 'number1',
      type: 'a8fields',
      valueSources: ['value'],
      listValues: Object.keys(customFields),
    },
  },
  jsonLogic: (formattedArgs) => {
    return { var: formattedArgs.number1.replaceAll("'", "") };
  },
  allowSelfNesting: true,
  formatFunc: (formattedArgs) => `${formattedArgs.number1.replaceAll("'", '')}`,
  sqlFormatFunc: (formattedArgs) =>
    `${formattedArgs.number1.replaceAll("'", '')}`,
});

export default ONLY_FIELDS;
