// Package Imports
import { BarChartOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const FrequencyResource: ResourceItem = {
  name: resourceName.frequency,
  identifier: ModuleIdentifierConst.FREQUENCY,
  list: `${resourceName.frequency}`,
  create: `${resourceName.frequency}/create`,
  edit: `${resourceName.frequency}/edit/:id`,
  show: `${resourceName.frequency}/show/:id`,
  options: { label: 'Frequency', icon: <BarChartOutlined /> },
};

export default FrequencyResource;
