// Package Imports
import React, { createContext, useContext, useEffect, useState } from 'react';
// Project Imports
import { RulesType } from 'contexts/rulesContext/type';

const RulesContext = createContext<RulesType>({
  rulesCurrent: 0,
  setRulesCurrent() {
    throw new Error('setRulesCurrent function is not implemented');
  },
  isFirstStepCompleted: false,
  setIsFirstStepCompleted() {
    throw new Error('setIsFirstStepCompleted function is not implemented');
  },
  isSecondStepCompleted: false,
  setIsSecondStepCompleted() {
    throw new Error('setIsSecondStepCompleted function is not implemented');
  },
  isThirdStepCompleted: false,
  setIsThirdStepCompleted() {
    throw new Error('setIsThirdStepCompleted function is not implemented');
  },
  rulePlanDataObj: null,
  setRulePlanDataObj() {
    throw new Error('setSelectFileObj function is not implemented');
  },
});

export function useRulesContext() {
  return useContext(RulesContext);
}

const RulesProvider = ({ children }: { children: React.ReactNode }) => {
  const [rulesCurrent, setRulesCurrent] = useState<any>(() => {
    const storedCurrent = localStorage.getItem('rulesCurrent');
    return Number(storedCurrent) || 0;
  });

  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [isSecondStepCompleted, setIsSecondStepCompleted] = useState(false);
  const [isThirdStepCompleted, setIsThirdStepCompleted] = useState(false);
  const [rulePlanDataObj, setRulePlanDataObj] = useState(() => {
    const storedSelectFileObj = localStorage.getItem('rulePlanDataObj');
    return storedSelectFileObj ? JSON.parse(storedSelectFileObj) : {};
  });

  useEffect(() => {
    localStorage.setItem('rulesCurrent', rulesCurrent);
  }, [rulesCurrent]);

  useEffect(() => {
    localStorage.setItem('rulePlanDataObj', JSON.stringify(rulePlanDataObj));
  }, [rulePlanDataObj]);

  return (
    <RulesContext.Provider
      value={{
        rulesCurrent,
        setRulesCurrent,
        isFirstStepCompleted,
        setIsFirstStepCompleted,
        isSecondStepCompleted,
        setIsSecondStepCompleted,
        isThirdStepCompleted,
        setIsThirdStepCompleted,
        rulePlanDataObj,
        setRulePlanDataObj,
      }}
    >
      {children}
    </RulesContext.Provider>
  );
};

export default RulesProvider;
