/* eslint-disable import/extensions */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';
import sqlFormatter from './sqlFormatter';

const SQLQueryBuilderCustomUtils = {
  sqlFormatter,
  formatSql: (sql) => {
    console.log('nilesh formatSql', sql);
    const finalSql = sql?.replaceAll('A8_NO_OPERATOR', '') || '';
    return finalSql;
  },
  generateFunction: (funcName, type, args = 1): FuncOrGroup => {
    const funcObj: FuncOrGroup = {
      label: funcName,
      sqlFunc: funcName,
      returnType: type,
      jsonLogic: funcName,
      args: {
        str: {
          label: type,
          type,
          valueSources: ['value', 'field', 'func'],
        },
      },
      allowSelfNesting: true,
    };
    if (args) {
      const finalArgs: any = {};
      for (let index = 0; index < args; index += 1) {
        const element = `${type}${index + 1}`;
        finalArgs[element] = {
          label: element,
          type,
          valueSources: ['value', 'field', 'func'],
        };
      }

      funcObj.args = finalArgs;
    }
    return funcObj;
  },
};
export default SQLQueryBuilderCustomUtils;
