// Package Imports
import { AppstoreAddOutlined } from '@ant-design/icons';
// Project Imports

import ModuleIdentifierConst from 'core/common/moduleIdentifier';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const RulePlansResource: ResourceItem = {
  name: resourceName.rulePlans,
  identifier: ModuleIdentifierConst.RULE_PLAN,
  list: `${resourceName.rulePlans}`,
  show: `${resourceName.rulePlans}/show/:id`,
  create: `${resourceName.rulePlans}/create`,
  edit: `${resourceName.rulePlans}/edit/:id`,
  options: { label: 'Rule Plan', icon: <AppstoreAddOutlined /> },
  // meta: {
  //   label: 'Rule Plans',
  //   icon: <AppstoreAddOutlined />,
  //   parent: resourceName.ruleManagement,
  // },
};

export default RulePlansResource;
