// Package Imports
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Authenticated } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import { ThemedLayoutV2 } from '@refinedev/antd';

// Project Imports
import DashboardRoute from 'core/routes/DashboardRoute';
import ErrorRoute from 'core/routes/ErrorRoute';
import LoginRoute from 'core/routes/LoginRoute';
import Header from 'components/header';
import routeName from 'core/common/routeName';
import resourceName from 'core/common/resourceName';
import CustomSider from 'components/sider/sider';
import RulePlansRoute from 'core/routes/RulePlansRoute';
// import RuleGroupsRoute from 'core/routes/RuleGroupsRoute';
// import RulesRoute from 'core/routes/RulesRoute';
import ProgramRoute from 'core/routes/Program';
import FrequencyRoute from 'core/routes/FrequencyRoute';
import WorkflowRoute from 'core/routes/WorkflowRoute';
import QueryBuilder from 'components/queryBuilder/query-builder';
import ProfileRoute from 'core/routes/ProfileRoute';

const AppRoutes: React.FC = () => (
  <Routes>
    <Route
      element={
        <Authenticated fallback={<CatchAllNavigate to={routeName.login} />}>
          <ThemedLayoutV2
            Header={() => <Header />}
            Sider={() => <CustomSider />}
          >
            <Outlet />
          </ThemedLayoutV2>
        </Authenticated>
      }
    >
      <Route
        index
        element={<NavigateToResource resource={resourceName.ruleDashboard} />}
      />
      {DashboardRoute}
      {ErrorRoute}
      {RulePlansRoute}
      {/* {RuleGroupsRoute} */}
      {/* {RulesRoute} */}
      {ProgramRoute}
      {FrequencyRoute}
      {WorkflowRoute}
      {ProfileRoute}
      <Route path="/query" element={<QueryBuilder />} />
    </Route>
    {ErrorRoute}
    <Route
      element={
        <Authenticated fallback={<Outlet />}>
          <NavigateToResource />
        </Authenticated>
      }
    >
      {LoginRoute}
    </Route>
  </Routes>
);

export default AppRoutes;
