/* eslint-disable import/extensions */
import {
  Query,
  Builder,
  Utils as QbUtils,
  Config,
  ImmutableTree,
  BuilderProps,
  Fields,
  JsonTree,
} from '@react-awesome-query-builder/antd';

import { useState, useCallback, useEffect } from 'react';
import loadConfig from './config';
import './queryBuilder.css';
import TypeAndQueryCard from './TypeAndQueryCard';
import SQLQueryBuilderCustomUtils from './custom_utils/SQLQueryBuilderCustomUtils';

interface DemoQueryBuilderState {
  tree: ImmutableTree;
  config: Config;
  skin: string;
  renderBocks: Record<string, boolean>;
  spelStr: string;
  spelErrors: Array<string>;
}
const WhenConditionCard = ({
  fields,
  onSave,
  value,
}: {
  fields: Fields;
  onSave: Function;
  value: any;
}) => {
  const loadedConfig = loadConfig('antd', fields);
  const emptyInitValue: JsonTree = { id: QbUtils.uuid(), type: 'group' };
  const [thenObj, setThenObj] = useState<any>({
    query: value.thenQuery,
    type: value.thenType,
    value: value.thenValue,
  });
  const [whenObj, setWhenObj] = useState<any>({
    query: value.when,
    value: value.whenValue,
  });
  const initTree: ImmutableTree = QbUtils.loadTree(
    value.when || emptyInitValue
  );
  const [state, setState] = useState<DemoQueryBuilderState>({
    tree: initTree,
    config: loadedConfig,
    skin: 'antd',
    spelStr: '',
    spelErrors: [] as Array<string>,
    renderBocks: {
      validation: true,
      jsonlogic: true,
      elasticSearch: true,
      mongo: true,
      jsTree: true,
      spel: true,
      strings: true,
      sql: true,
    },
  });

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container">
        <div className="query-builder">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  );

  const onChange = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      setState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config,
      }));
      setWhenObj({
        query: QbUtils.getTree(immutableTree),
        value: SQLQueryBuilderCustomUtils.formatSql(
          QbUtils.sqlFormat(immutableTree, loadedConfig)
        ),
      });
    },
    []
  );
  useEffect(() => {
    console.log('whenObj', whenObj);
    onSave({
      when: whenObj.query,
      whenSql: SQLQueryBuilderCustomUtils.formatSql(
        QbUtils.sqlFormat(state.tree, loadedConfig)
      ),
      whenValue: SQLQueryBuilderCustomUtils.formatSql(
        QbUtils.sqlFormat(state.tree, loadedConfig)
      ),
      whenJsonLogic: QbUtils.jsonLogicFormat(state.tree, loadedConfig),
      thenValue: thenObj.value,
      thenQuery: thenObj.query,
      thenSql: thenObj.sql,
      thenType: thenObj.type,
      thenJsonLogic: thenObj.jsonLogic,
    });
  }, [whenObj, thenObj]);
  const onTypeSave = (data: any) => {
    setThenObj(data);
  };
  return (
    <main className="when-main-container">
      <section className="when-container">
        <span>When</span>
        <section>
          <Query
            {...state.config}
            value={state.tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
          />
        </section>
      </section>
      <section className="then-main-container">
        <TypeAndQueryCard
          onSave={onTypeSave}
          data={thenObj}
          fields={fields}
          title="Then"
        />
      </section>
    </main>
  );
};
export default WhenConditionCard;
