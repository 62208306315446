/* eslint-disable @typescript-eslint/naming-convention */
import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const SUBSTRING: FuncOrGroup = {
  label: 'SUBSTRING',
  sqlFunc: 'SUBSTRING',
  returnType: 'text',
  jsonLogic: "substr",
  args: {
    str: {
      label: 'string',
      type: 'text',
      valueSources: ['value', 'field', 'func'],
    },
    start_position: {
      label: 'start position',
      type: 'number',
      valueSources: ['value', 'field', 'func'],
    },
    length: {
      label: 'length',
      type: 'number',
      valueSources: ['value', 'field', 'func'],
    },
  },
  allowSelfNesting: true,
};

export default SUBSTRING;
