import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const ROUND: FuncOrGroup = {
  label: 'ROUND',
  sqlFunc: 'ROUND',
  returnType: 'number',
  args: {
    number: {
      label: 'number',
      type: 'number',
      valueSources: ['value', 'field', 'func'],
    },
    decimals: {
      label: 'decimals',
      type: 'number',
      valueSources: ['value'],
    },
    operation: {
      label: 'operation',
      type: 'number',
      valueSources: ['value'],
    },
  },
  jsonLogic: "ROUND",
  allowSelfNesting: true,
};

export default ROUND;
