const ModuleIdentifierConst = {
  RULES: 'rulesManagement',
  RULE: 'rulesList',
  RULE_GROUP: 'ruleGroupsList',
  RULE_PLAN: 'rulePlansList',
  FREQUENCY: 'frequencyMasterManagement',
  PAYOUT_FREQUENCY: 'payoutFrequency',
  WORKFLOW: 'workflowMasterManagement',
  PROGRAM: 'configureProgramManagement',
};

export default ModuleIdentifierConst;
