// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const PayoutFrequencyList = lazy(() => import('pages/payoutFrequency/list'));
const PayoutFrequencyCreate = lazy(
  () => import('pages/payoutFrequency/create')
);
const PayoutFrequencyEdit = lazy(() => import('pages/payoutFrequency/edit'));
const PayoutFrequencyShow = lazy(() => import('pages/payoutFrequency/show'));

const FrequencyRoute = (
  <Route path={routeName.frequency}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.FREQUENCY_VIEW,
            PrivateRouteConst.SELF_FREQUENCY_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <PayoutFrequencyList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={[PrivateRouteConst.CREATE_FREQUENCY]}>
          <Suspense fallback={<Loading />}>
            <PayoutFrequencyCreate />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.FREQUENCY_EDIT,
            PrivateRouteConst.SELF_FREQUENCY_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <PayoutFrequencyEdit />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.FREQUENCY_VIEW,
            PrivateRouteConst.SELF_FREQUENCY_DELETE,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <PayoutFrequencyShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default FrequencyRoute;
