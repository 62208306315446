// Package Imports
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'i18n';
// Project Imports
import keycloak from 'keycloak/keyCloak';
import App from 'App';
import TokenProvider from 'contexts/resource/ResourceContext';
import ProgramConfigProvider from 'contexts/programConfig/programConfigContext';
import RulesProvider from 'contexts/rulesContext/RulesContext';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <TokenProvider>
      <ProgramConfigProvider>
        <RulesProvider>
          <ReactKeycloakProvider authClient={keycloak}>
            <React.StrictMode>
              <React.Suspense fallback="loading">
                <App />
              </React.Suspense>
            </React.StrictMode>
          </ReactKeycloakProvider>
        </RulesProvider>
      </ProgramConfigProvider>
    </TokenProvider>
  );
} else {
  console.error('Root element with id');
}
