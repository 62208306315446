// Package Imports
import { SettingOutlined } from '@ant-design/icons';

// Project Imports
import ModuleIdentifierConst from 'core/common/moduleIdentifier';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const ProgramResource: ResourceItem = {
  name: resourceName.program,
  identifier: ModuleIdentifierConst.PROGRAM,
  list: `${resourceName.program}`,
  create: `${resourceName.program}/create/:id`,
  edit: `${resourceName.program}/edit/:id`,
  show: `${resourceName.program}/show/:id`,
  options: { label: 'Program', icon: <SettingOutlined /> },
};

export default ProgramResource;
