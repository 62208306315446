// Package Imports
import React, { createContext, useContext, useEffect, useState } from 'react';
// Project Imports
import { ProgramConfigType } from 'contexts/programConfig/type';

const ProgramConfigContext = createContext<ProgramConfigType>({
  programCurrent: 0,
  setProgramCurrent() {
    throw new Error('setProgramCurrent function is not implemented');
  },
  isFirstStepCompleted: false,
  setIsFirstStepCompleted() {
    throw new Error('setIsFirstStepCompleted function is not implemented');
  },
  isSecondStepCompleted: false,
  setIsSecondStepCompleted() {
    throw new Error('setIsSecondStepCompleted function is not implemented');
  },
  isThirdStepCompleted: false,
  setIsThirdStepCompleted() {
    throw new Error('setIsSecondStepCompleted function is not implemented');
  },
});

export function useProgramConfigContext() {
  return useContext(ProgramConfigContext);
}

const ProgramConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [programCurrent, setProgramCurrent] = useState<any>(() => {
    const storedCurrent = localStorage.getItem('programCurrent');
    return Number(storedCurrent) || 0;
  });
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [isSecondStepCompleted, setIsSecondStepCompleted] = useState(false);
  const [isThirdStepCompleted, setIsThirdStepCompleted] = useState(false);

  useEffect(() => {
    localStorage.setItem('programCurrent', programCurrent);
  }, [programCurrent]);

  return (
    <ProgramConfigContext.Provider
      value={{
        programCurrent,
        setProgramCurrent,
        isFirstStepCompleted,
        setIsFirstStepCompleted,
        isSecondStepCompleted,
        setIsSecondStepCompleted,
        isThirdStepCompleted,
        setIsThirdStepCompleted,
      }}
    >
      {children}
    </ProgramConfigContext.Provider>
  );
};

export default ProgramConfigProvider;
