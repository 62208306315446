import { FuncOrGroup } from '@react-awesome-query-builder/antd';

const SUBTRACT: FuncOrGroup = {
  label: 'SUBTRACT',
  sqlFunc: 'SUBTRACT',
  returnType: 'number',
  args: {
    number1: {
      label: 'number1',
      type: 'number',
      valueSources: ['value', 'field', 'func'],
    },
    number2: {
      label: 'number2',
      type: 'number',
      valueSources: ['value', 'field', 'func'],
    },
  },
  jsonLogic: "-",
  allowSelfNesting: true,
  formatFunc: (formattedArgs) =>
    `(${formattedArgs.number1} - ${formattedArgs.number2})`,
  sqlFormatFunc: (formattedArgs) =>
    `(${formattedArgs.number1} - ${formattedArgs.number2})`,
};

export default SUBTRACT;
