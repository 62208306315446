// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const ProgramList = lazy(() => import('pages/program/list'));
const ProgramConfigCreate = lazy(() => import('pages/programConfig/create'));

const ProgramRoute = (
  <Route path={routeName.program}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.CONFIG_PROGRAM_VIEW,
            PrivateRouteConst.SELF_CONFIG_PROGRAM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <ProgramList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create/:id"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_CONFIG_PROGRAM}>
          <Suspense fallback={<Loading />}>
            <ProgramConfigCreate type="Create" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.CONFIG_PROGRAM_EDIT,
            PrivateRouteConst.SELF_CONFIG_PROGRAM_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <ProgramConfigCreate type="Create" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.CONFIG_PROGRAM_VIEW,
            PrivateRouteConst.SELF_CONFIG_PROGRAM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <ProgramConfigCreate type="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default ProgramRoute;
