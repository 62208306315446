// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const WorkflowConfigCreate = lazy(
  () => import('pages/workflowConfig/WorkflowConfig')
);
const WorkflowList = lazy(() => import('pages/workflowConfig/list'));

const WorkflowRoute = (
  <Route path={routeName.workflow}>
    <Route
      index
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.WORKFLOW_VIEW,
            PrivateRouteConst.SELF_WORKFLOW_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <WorkflowList />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_WORKFLOW}>
          <Suspense fallback={<Loading />}>
            <WorkflowConfigCreate type="Create" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.WORKFLOW_EDIT,
            PrivateRouteConst.SELF_WORKFLOW_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <WorkflowConfigCreate type="Edit" />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.WORKFLOW_VIEW,
            PrivateRouteConst.SELF_WORKFLOW_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <WorkflowConfigCreate type="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default WorkflowRoute;
