/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
const dummy1: any = {
  caseList: [
    {
      when: {
        id: '9889b899-cdef-4012-b456-718f8556824a',
        type: 'group',
        children1: [
          {
            type: 'rule',
            id: '99b88b99-89ab-4cde-b012-318f9ee25d80',
            properties: {
              fieldSrc: 'func',
              field: {
                func: 'string.CONCAT',
                args: {
                  str: {
                    valueSrc: 'func',
                    value: {
                      func: 'select.toSelectString',
                      args: {
                        strList: {
                          valueSrc: 'value',
                          value: 'BOM_BKT,FINAL_RESOLUTION,mr,10',
                        },
                      },
                    },
                  },
                  str2: {
                    valueSrc: 'value',
                    value: 'test',
                  },
                },
              },
              operator: 'a8NoOperator',
              valueType: [],
              value: [],
              valueSrc: [],
              valueError: [],
            },
          },
        ],
      },
      whenSql: "CONCAT((BOM_BKT,FINAL_RESOLUTION,'mr','10'), 'test')",
      whenValue: "CONCAT((BOM_BKT,FINAL_RESOLUTION,'mr','10'), 'test')",
      thenValue:
        "(PV_FINAL_PRODUCT_NEW IN ('BL', 'CCOD_PL', 'CCOD_BL') AND POS = '2')",
      thenQuery: {
        id: 'bbabbb9b-cdef-4012-b456-718f9b8f66e8',
        type: 'group',
        children1: [
          {
            type: 'rule',
            id: 'aa9b9a99-89ab-4cde-b012-318f9b8f8d83',
            properties: {
              fieldSrc: 'field',
              field: 'PV_FINAL_PRODUCT_NEW',
              operator: 'select_any_in',
              value: [['BL', 'CCOD_PL', 'CCOD_BL']],
              valueSrc: ['value'],
              valueError: [null],
              valueType: ['multiselect'],
            },
          },
          {
            type: 'rule',
            id: 'b8a88b89-cdef-4012-b456-718f9b9007ba',
            properties: {
              fieldSrc: 'field',
              field: 'POS',
              operator: 'equal',
              value: [2],
              valueSrc: ['value'],
              valueError: [null],
              valueType: ['number'],
            },
          },
        ],
      },
      thenSql:
        "(PV_FINAL_PRODUCT_NEW IN ('BL', 'CCOD_PL', 'CCOD_BL') AND POS = '2')",
      thenType: 'QUERY',
    },
    {
      when: {
        id: '9b99a9ab-cdef-4012-b456-718f85579922',
        type: 'group',
        children1: [
          {
            type: 'group',
            id: 'abab888a-0123-4456-b89a-b18f8557a29f',
            properties: {
              conjunction: 'AND',
              not: false,
            },
            children1: [
              {
                type: 'rule',
                id: '8b8988bb-cdef-4012-b456-718f8557a29f',
                properties: {
                  fieldSrc: 'field',
                  field: 'BOM_BKT',
                  operator: 'select_equals',
                  value: ['X'],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
              {
                type: 'rule',
                id: 'a8bbba9b-4567-489a-bcde-f18f8557c0fa',
                properties: {
                  fieldSrc: 'field',
                  field: 'PV_FINAL_PRODUCT_NEW',
                  operator: 'select_equals',
                  value: ['MBL'],
                  valueSrc: ['value'],
                  valueError: [null],
                  valueType: ['select'],
                },
              },
            ],
          },
        ],
      },
      whenSql: "(BOM_BKT = 'X' AND PV_FINAL_PRODUCT_NEW = 'MBL')",
      whenValue: "(BOM_BKT = 'X' AND PV_FINAL_PRODUCT_NEW = 'MBL')",
      thenValue:
        'ROUND((FINAL_RESOLUTION + (GREATEST(FINAL_RESOLUTION,BOM_BKT,PV_FINAL_PRODUCT_NEW,POS,180) * LEAST(FINAL_RESOLUTION,POS,10))), 2, 0)',
      thenQuery: {
        id: '8898bba9-4567-489a-bcde-f18f9b8f673d',
        type: 'group',
        children1: [
          {
            type: 'rule',
            id: 'abb8aaaa-89ab-4cde-b012-318fa51d7b06',
            properties: {
              fieldSrc: 'func',
              field: {
                func: 'number.ROUND',
                args: {
                  number: {
                    valueSrc: 'func',
                    value: {
                      func: 'number.ADD',
                      args: {
                        number1: {
                          valueSrc: 'func',
                          value: {
                            func: 'number.ONLY_FIELDS',
                            args: {
                              number1: {
                                valueSrc: 'value',
                                value: 'FINAL_RESOLUTION',
                              },
                            },
                          },
                        },
                        number2: {
                          valueSrc: 'func',
                          value: {
                            func: 'number.MULTIPLY',
                            args: {
                              number1: {
                                valueSrc: 'func',
                                value: {
                                  func: 'number.GREATEST',
                                  args: {
                                    number1: {
                                      valueSrc: 'value',
                                      value:
                                        'FINAL_RESOLUTION,BOM_BKT,PV_FINAL_PRODUCT_NEW,POS,180',
                                    },
                                  },
                                },
                              },
                              number2: {
                                valueSrc: 'func',
                                value: {
                                  func: 'number.LEAST',
                                  args: {
                                    number1: {
                                      valueSrc: 'value',
                                      value: 'FINAL_RESOLUTION,POS,10',
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  decimals: {
                    valueSrc: 'value',
                    value: 2,
                  },
                  operation: {
                    valueSrc: 'value',
                    value: 0,
                  },
                },
              },
              operator: 'a8NoOperator',
              value: [],
              valueSrc: [],
              valueError: [],
              valueType: [],
            },
          },
        ],
      },
      thenSql:
        'ROUND((FINAL_RESOLUTION + (GREATEST(FINAL_RESOLUTION,BOM_BKT,PV_FINAL_PRODUCT_NEW,POS,180) * LEAST(FINAL_RESOLUTION,POS,10))), 2, 0)',
      thenType: 'QUERY',
    },
    {
      when: {
        id: '8989ba98-4567-489a-bcde-f18f9b90251b',
        type: 'group',
        children1: [
          {
            type: 'rule',
            id: 'abaaa9ba-89ab-4cde-b012-318f9b902dcc',
            properties: {
              fieldSrc: 'field',
              field: 'BOM_BKT',
              operator: 'select_equals',
              value: ['2'],
              valueSrc: ['value'],
              valueError: [null],
              valueType: ['select'],
            },
          },
          {
            type: 'rule',
            id: '989b8bba-cdef-4012-b456-718f9b916399',
            properties: {
              fieldSrc: 'field',
              field: null,
              operator: null,
              value: [],
              valueSrc: [],
              valueError: [],
            },
          },
        ],
      },
      whenSql: "BOM_BKT = '2'",
      whenValue: "BOM_BKT = '2'",
      thenValue:
        "SUBSTRING(CONCAT(UPPER(PV_FINAL_PRODUCT_NEW), TRIM(FINAL_RESOLUTION)), LENGTH(CONCAT('Mr.', BOM_BKT)), 10)",
      thenQuery: {
        id: '8989ba98-4567-489a-bcde-f18f9b90251b',
        type: 'group',
        children1: [
          {
            type: 'rule',
            id: '898b99aa-89ab-4cde-b012-318fa5727b10',
            properties: {
              fieldSrc: 'func',
              field: {
                func: 'string.SUBSTRING',
                args: {
                  str: {
                    valueSrc: 'func',
                    value: {
                      func: 'string.CONCAT',
                      args: {
                        str: {
                          valueSrc: 'func',
                          value: {
                            func: 'string.UPPER',
                            args: {
                              text1: {
                                valueSrc: 'func',
                                value: {
                                  func: 'string.STRING_ONLY_FIELDS',
                                  args: {
                                    number1: {
                                      valueSrc: 'value',
                                      value: 'PV_FINAL_PRODUCT_NEW',
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                        str2: {
                          valueSrc: 'func',
                          value: {
                            func: 'string.TRIM',
                            args: {
                              text1: {
                                valueSrc: 'func',
                                value: {
                                  func: 'string.STRING_ONLY_FIELDS',
                                  args: {
                                    number1: {
                                      valueSrc: 'value',
                                      value: 'FINAL_RESOLUTION',
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  start_position: {
                    valueSrc: 'func',
                    value: {
                      func: 'string.LENGTH',
                      args: {
                        number1: {
                          valueSrc: 'func',
                          value: {
                            func: 'string.CONCAT',
                            args: {
                              str: {
                                valueSrc: 'value',
                                value: 'Mr.',
                              },
                              str2: {
                                valueSrc: 'func',
                                value: {
                                  func: 'string.STRING_ONLY_FIELDS',
                                  args: {
                                    number1: {
                                      valueSrc: 'value',
                                      value: 'BOM_BKT',
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  length: {
                    valueSrc: 'value',
                    value: 10,
                  },
                },
              },
              operator: 'a8NoOperator',
              value: [],
              valueSrc: [],
              valueError: [],
              valueType: [],
            },
          },
        ],
      },
      thenSql:
        "SUBSTRING(CONCAT(UPPER(PV_FINAL_PRODUCT_NEW), TRIM(FINAL_RESOLUTION)), LENGTH(CONCAT('Mr.', BOM_BKT)), 10)",
      thenType: 'QUERY',
    },
  ],
  elseType: 'QUERY',
  elseValue: "CONCAT(POS, '100')",
  elseQuery: {
    id: 'a9baa99a-cdef-4012-b456-718f9bdcf563',
    type: 'group',
    children1: [
      {
        type: 'rule',
        id: 'a99889b9-cdef-4012-b456-718f9bde68f2',
        properties: {
          fieldSrc: 'func',
          field: {
            func: 'string.CONCAT',
            args: {
              strList: {
                valueSrc: 'value',
                value: 'BOM_BKT,POS',
              },
              str: {
                valueSrc: 'field',
                value: 'POS',
              },
              str2: {
                value: '100',
                valueSrc: 'value',
              },
            },
          },
          operator: 'a8NoOperator',
          value: [],
          valueSrc: [],
          valueError: [],
          valueType: [],
        },
      },
    ],
  },
};

const dummy2 = {
  value: '(10 + 12) = 24',
  query: {
    id: '8aaa8b9a-cdef-4012-b456-718fa5d63624',
    type: 'group',
    children1: [
      {
        type: 'rule',
        id: '9a9aab88-89ab-4cde-b012-318fa5d65228',
        properties: {
          fieldSrc: 'func',
          field: {
            func: 'number.ADD',
            args: {
              number1: {
                valueSrc: 'value',
                value: 10,
              },
              number2: {
                valueSrc: 'value',
                value: 12,
              },
            },
          },
          operator: 'equal',
          value: [24],
          valueSrc: ['value'],
          valueError: [null],
          valueType: ['number'],
        },
      },
    ],
  },
  sql: '(10 + 12) = 24',
  jsonLogic: {
    errors: ['Func number.ADD is not supported'],
    data: {},
  },
  type: 'QUERY',
};

const dummy3 = {
  value: 'SUBSTRING(FINAL_RESOLUTION, 10, 1) IS NOT NULL',
  query: {
    id: '8aaa8b9a-cdef-4012-b456-718fa5d63624',
    type: 'group',
    children1: [
      {
        type: 'rule',
        id: '9a9aab88-89ab-4cde-b012-318fa5d65228',
        properties: {
          fieldSrc: 'func',
          field: {
            func: 'string.SUBSTRING',
            args: {
              str: {
                valueSrc: 'func',
                value: {
                  func: 'string.STRING_ONLY_FIELDS',
                  args: {
                    number1: {
                      valueSrc: 'value',
                      value: 'FINAL_RESOLUTION',
                    },
                  },
                },
              },
              start_position: {
                valueSrc: 'value',
                value: 10,
              },
              length: {
                valueSrc: 'value',
                value: 1,
              },
            },
          },
          operator: 'is_not_null',
          value: [],
          valueSrc: [],
          valueError: [],
          valueType: [],
        },
      },
    ],
  },
  sql: 'SUBSTRING(FINAL_RESOLUTION, 10, 1) IS NOT NULL',
  jsonLogic: {
    errors: ['Func string.SUBSTRING is not supported'],
    data: {},
  },
  type: 'QUERY',
};
const dummyJson = dummy1;
export default dummyJson;
