// Package Imports
import { notification } from 'antd';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

const openNotificationWithIcon = (
  type: string | NotificationType,
  message: any,
  description?: any
) => {
  let desc: React.ReactNode = description;

  if (description) {
    const parts = description
      .split('|')
      .map((part, index) => <p key={index}>{part}</p>);
    desc = parts;
  }

  notification[type]({
    message,
    description: desc,
  });
};

export default openNotificationWithIcon;
