/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/extensions */
import { TypedMap, FuncOrGroup } from '@react-awesome-query-builder/antd';
import SQLQueryBuilderCustomUtils from '../custom_utils/SQLQueryBuilderCustomUtils';
import ROUND from './number/ROUND';
import ADD from './number/ADD';
import SUBTRACT from './number/SUBTRACT';
import DIVIDE from './number/DIVIDE';
import MULTIPLY from './number/MULTIPLY';
import EXPONENTIATION from './number/EXPONENTIATION';
import SUBSTRING from './string/SUBSTRING';

interface InterfaceSqlBuilderFunctions {
  number: TypedMap<FuncOrGroup>;
  string: TypedMap<FuncOrGroup>;
}
const sqlBuilderFunctions: InterfaceSqlBuilderFunctions = {
  number: {},
  string: {},
};

const number: TypedMap<FuncOrGroup> = {};
number.ABS = SQLQueryBuilderCustomUtils.generateFunction('ABS', 'number');
number.CEIL = SQLQueryBuilderCustomUtils.generateFunction('CEIL', 'number');
number.CEILING = SQLQueryBuilderCustomUtils.generateFunction(
  'CEILING',
  'number'
);
number.DEGREES = SQLQueryBuilderCustomUtils.generateFunction(
  'DEGREES',
  'number'
);
number.FLOOR = SQLQueryBuilderCustomUtils.generateFunction('FLOOR', 'number');
number.POW = SQLQueryBuilderCustomUtils.generateFunction('POW', 'number', 2);
number.POWER = SQLQueryBuilderCustomUtils.generateFunction(
  'POWER',
  'number',
  2
);
number.RADIANS = SQLQueryBuilderCustomUtils.generateFunction(
  'RADIANS',
  'number'
);
number.ROUND = ROUND;
number.SQRT = SQLQueryBuilderCustomUtils.generateFunction('SQRT', 'number');
number.TRUNCATE = SQLQueryBuilderCustomUtils.generateFunction(
  'TRUNCATE',
  'number',
  2
);

number.ADD = ADD;
number.SUBTRACT = SUBTRACT;
number.DIVIDE = DIVIDE;
number.MULTIPLY = MULTIPLY;
number.EXPONENTIATION = EXPONENTIATION;
sqlBuilderFunctions.number = number;

const string: TypedMap<FuncOrGroup> = {};
string.LOWER = SQLQueryBuilderCustomUtils.generateFunction('LOWER', 'text');
string.UPPER = SQLQueryBuilderCustomUtils.generateFunction('UPPER', 'text');
string.TRIM = SQLQueryBuilderCustomUtils.generateFunction('TRIM', 'text');

string.SUBSTRING = SUBSTRING;
sqlBuilderFunctions.string = string;

export default sqlBuilderFunctions;
